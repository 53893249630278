<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Diploma Content
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form ref="form" class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="6">
            <b-form-group label="Category" label-for="blog-edit-category" class="mb-2">
              <validation-provider #default="{ errors }" name="Category" rules="required">
                <v-select
                data-testid="content-category-select"
                  v-model="addCourseForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Diploma" label-for="blog-edit-category" class="mb-2">
              <validation-provider #default="{ errors }" name="Diplomas" rules="required">
                <v-select
                data-testid="content-diploma-select"
                  v-model="addCourseForm.course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  @input="getMonths(addCourseForm.course_id)"
                >
                  <template v-if="!getCourse" #no-options="{ search, searching, loading }">
                    Select Category First ...
                  </template>
                  <template v-else #no-options="{ search, searching, loading }">
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Related Diploma" label-for="blog-edit-course" class="mb-2">
              <validation-provider #default="{ errors }" name="related_Diplomas" rules="">
                <v-select
                data-testid="content-related-diploma"
                  v-model="addCourseForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  multiple
                  :options="RelatedCourse"
                  :reduce="(val) => val.id"
                >
                  <template #no-options="{ search, searching, loading }">
                    No data ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Description" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Description" rules="required">
                <quill-editor
                  ref="descriptionEditor"
                  v-model="addCourseForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Objectives" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="objectives" rules="required">
                <quill-editor
                  ref="objectivesEditor"
                  v-model="addCourseForm.objectives"
                  :options="getEditorOptions('objectivesEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <h3>Months</h3>
            <div class="border rounded p-2">
              <b-row v-for="(item, i) in addCourseForm.months" :key="i" no-body class="border rounded p-2">
                <br />
                <b-card-text class="my-50" />
                <b-col cols="2">
                  <h4>Month {{ i + 1 }}</h4>
                </b-col>
                <b-col cols="10" class="mt-50" style="text-align: end">
                  <b-button
                    v-if="i != 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline"
                    class="mr-1"
                    @click="removeMonth(i)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Name" label-for="blog-edit-title" class="mb-2">
                    <validation-provider #default="{ errors }" name="Tab Image">
                      <b-form-input 
                      data-testid="month-name"
                     
                      v-model="item.name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="number" label-for="blog-number" class="mb-2">
                    {{ (item.number = i + 1) }}
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group 
                  id="month-description"
                  label="description" 
                  label-for="blog-edit-title" 
                  class="mb-2">
                    <quill-editor
                      ref="itemDescriptionEditor"
                      v-model="item.description"
                      :options="getEditorOptions('itemDescriptionEditor')"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-col cols="12" class="mb-2">
                    <h3>Weeks</h3>
                    <div class="border rounded p-2">
                      <b-row v-for="(week, weekIndex) in item.weeks" :key="weekIndex" no-body class="border rounded p-2">
                        <br />
                        <b-card-text class="my-50" />
                        <b-col md="2" xl="2" sm="12">
                          <h4>Week {{ weekIndex + 1 }}</h4>
                        </b-col>
                        <b-col cols="10" class="mt-50" style="text-align: end">
                          <b-button
                            v-if="weekIndex != 0"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline"
                            class="mr-1"
                            @click="removeWeek(weekIndex, i)"
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </b-col>

                        <b-col md="6">
                          <b-form-group label="Name" label-for="blog-edit-title" class="mb-2">
                            <validation-provider #default="{ errors }" name="Tab Image">
                              <b-form-input 
                              data-testid="weak-name"
                               v-model="week.name" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group label="number" label-for="blog-number" class="mb-2">
                            {{ (week.number = weekIndex + 1) }}
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group 
                          id="weak-description"
                          label="description" 
                          label-for="blog-edit-title"
                           class="mb-2">
                            <quill-editor
                              ref="weekDescriptionEditor"
                              v-model="week.description"
                              :options="getEditorOptions('weekDescriptionEditor')"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-col cols="12" class="mb-2">
                            <h3>Days</h3>
                            <div class="border rounded p-2">
                              <b-row
                                v-for="(day, daysIndex) in week.days"
                                :key="daysIndex"
                                no-body
                                class="border rounded p-2"
                              >
                                <br />
                                <b-card-text class="my-50" />
                                <b-col md="2" xl="2" sm="12">
                                  <h4>Day {{ daysIndex + 1 }}</h4>
                                </b-col>
                                <b-col cols="10" class="mt-50" style="text-align: end">
                                  <b-button
                                    v-if="daysIndex != 0"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="outline"
                                    class="mr-1"
                                    @click="removeDay(daysIndex, weekIndex, i)"
                                  >
                                    <feather-icon icon="TrashIcon" />
                                  </b-button>
                                </b-col>

                                <b-col md="6">
                                  <b-form-group label="Name" label-for="blog-edit-title" class="mb-2">
                                    <validation-provider #default="{ errors }" name="Tab Image">
                                      <b-form-input 
                                      data-testid="day-name"
                                      id="alt-image"
                                       v-model="day.name" />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <b-col md="6">
                                  <b-form-group label="number" label-for="blog-number" class="mb-2">
                                    {{ (day.number = daysIndex + 1) }}
                                  </b-form-group>
                                </b-col>
                                <b-col md="12">
                                  <b-form-group 
                                  id="day-description"
                                  label="description"
                                   label-for="blog-edit-title" class="mb-2">
                                    <quill-editor
                                      ref="dayDescriptionEditor"
                                      v-model="day.description"
                                      :options="getEditorOptions('dayDescriptionEditor')"
                                    />
                                  </b-form-group>
                                </b-col>

                                <hr />
                              </b-row>

                              <b-row class="text-end" style="text-align: end">
                                <b-col cols="12" class="mt-50">
                                  <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    class="mr-1"
                                    @click="newDay(weekIndex, i)"
                                  >
                                    New Day
                                  </b-button>
                                </b-col>
                              </b-row>
                            </div>
                          </b-col>
                        </b-col>
                        <hr />
                      </b-row>

                      <b-row class="text-end" style="text-align: end">
                        <b-col cols="12" class="mt-50">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            @click="newWeek(i)"
                          >
                            New Week
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-col>
                <hr />
              </b-row>

              <b-row class="text-end" style="text-align: end">
                <b-col cols="12" class="mt-50">
                  <b-button
                  data-testid="diploma-new-month"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="newMonth"
                  >
                    New Month
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group 
            
            label="Who should attend?" label-for="blog-content" class="mb-2">
              <validation-provider 
              #default="{ errors }" 
              name="Who should attend?"
               rules="required">
                <quill-editor
                  ref="attendersEditor"
                  v-model="addCourseForm.attenders"
                  :options="getEditorOptions('attendersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Og image</h4>

              <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="addCourseForm.og_image !=''?`https://lpcentre.com/${addCourseForm.og_image}` :Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage; addCourseForm.og_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group label="Image" label-for="blog-edit-title" class="mb-2">
                      <validation-provider 
                      #default="{ errors }" 
                      name="Og Image" 
                      rules="required">
                        <b-form-file
                        data-testid="diploma-og-image"
                          ref="refInputE2"
                          v-model="addCourseForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Alt Image" label-for="blog-edit-title" class="mb-2">
                      <b-form-input id="alt-image" v-model="addCourseForm.alt_content_image" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
            data-testid="diploma-submit-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
        <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!--/ form -->
  </b-card>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>

<style lang="scss" scoped>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}

/* Custom styles to fix the overlapping issue */
.blog-edit-wrapper {
  .quill-editor {
    max-height: 400px; /* Limit height to prevent overflow */
    overflow-y: auto; /* Add scrollbar if content overflows */
  }

  .ql-toolbar.ql-snow {
    z-index: 10; /* Ensure toolbar is above other elements */
  }

  .ql-container.ql-snow {
    z-index: 9; /* Ensure container is below the toolbar */
  }
}
</style>

<script>
import {
  BCard,
  BMedia,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, watch, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import 'quill/dist/quill.snow.css'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const { proxy } = getCurrentInstance()
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const getCourse = ref(false)
    const durationList = ref([])
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const coursesList = ref([])
    const RelatedCourse = ref([])
    const dayContent = ref([])
    const categoriesList = ref([])

    const addCourseForm = reactive({
      category_id: '',
      course_id: '',
      description: '',
      alt_content_image: '',
      og_image: '',
      objectives: '',
      attenders: '',
      related_courses: '',
      days_content: '',
      day: 1,
      months: [
        {
          name: '',
          number: '',
          description: '',
          weeks: [
            {
              name: '',
              number: '',
              month_id: '',
              description: '',
              days: [
                {
                  name: '',
                  number: '',
                  week_id: '',
                  description: '',
                },
              ],
            },
          ],
        },
      ],
    })

    const getCoursesByCategory = (id) => {
      getCourse.value = true
      addCourseForm.course_id = ''
      coursesList.value = []
      RelatedCourse.value = []
      store
        .dispatch('longcourses/getCoursesByCategory', {
          category_id: id,
        })
        .then((response) => {
          response.data.data.forEach((el) => {
            const course = {
              id: el.id,
              name: el.name,
              link_id: el.link_id,
              related_courses: el.related_courses,
              attenders: el.attenders,
              objectives: el.objectives,
              months: el.months,
              description: el.description,
              og_image: el.og_image,
              alt_content_image: el.alt_content_image,
            }
            if (el.online == 1) {
              course.name = `${el.name} Online`
            }
            coursesList.value.push(course)
          })
          RelatedCourse.value = coursesList.value
        })
    }

    const form = ref()
    const daycontentArray = ref([])
    const dayContentForm = ref('')
    watch(dayContentForm, (val, oldVal) => {
      if (!dayContentForm.value) {
        dayContentForm.value = ''
      }

      if (dayContentForm.value !== '') {
        daycontentArray.value[addCourseForm.day - 1] = dayContentForm.value
      } else if (daycontentArray.value[addCourseForm.day - 1]) {
        dayContentForm.value = daycontentArray.value[addCourseForm.day - 1]
      }
    })

    const newMonth = () => {
      addCourseForm.months.push({
        name: '',
        number: '',
        description: '',
        weeks: [
          {
            name: '',
            number: '',
            month_id: '',
            description: '',
            days: [
              {
                name: '',
                number: '',
                week_id: '',
                description: '',
              },
            ],
          },
        ],
      })
    }
    const removeMonth = (i) => {
      addCourseForm.months.splice(i, 1)
    }
    const newWeek = (i) => {
      addCourseForm.months[i].weeks.push({
        name: '',
        number: '',
        description: '',
        days: [
          {
            name: '',
            number: '',
            week_id: '',
            description: '',
          },
        ],
      })
    }

    const removeWeek = (i, monthIndex) => {
      addCourseForm.months[monthIndex].weeks.splice(i, 1)
    }
    const newDay = (weekIndex, monthIndex) => {
      addCourseForm.months[monthIndex].weeks[weekIndex].days.push({
        name: '',
        number: '',
        description: '',
      })
    }
    const removeDay = (daysIndex, weekIndex, monthIndex) => {
      addCourseForm.months[monthIndex].weeks[weekIndex].days.splice(daysIndex, 1)
    }
    const isLoading = ref(false)
    store.dispatch('categories/AllCategory').then((response) => {
      categoriesList.value = response.data.data
    })

    const { inputImageRendererTab } = useInputImageRenderer(refInputE2, (base64) => {
      refPreviewE2.value.src = base64
    })
    const addCourseFormvalidate = ref()
    const simple = ref()

    const save = () => {
      const formData = new FormData()

      formData.append('category_id', addCourseForm.category_id)
      formData.append('long_course_id', addCourseForm.course_id.id)
      formData.append('description', addCourseForm.description)
      formData.append('objectives', addCourseForm.objectives)
      formData.append('attenders', addCourseForm.attenders)
      formData.append('og_image', addCourseForm.og_image)
      formData.append('alt_content_image', addCourseForm.alt_content_image)
      if (addCourseForm.related_courses == null) {
        formData.append('related_courses', JSON.stringify([]))
      } else {
        formData.append('related_courses', JSON.stringify(addCourseForm.related_courses))
      }
      if (addCourseForm.months == null) {
        formData.append('months', JSON.stringify([]))
      } else {
        formData.append('months', JSON.stringify(addCourseForm.months))
      }
      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          isLoading.value = true
          store
            .dispatch('content/addLongCourseContent', formData)
            .then((response) => {
              isLoading.value = false
              daycontentArray.value = []
              dayContentForm.value = ''
              durationList.value = []
              addCourseForm.day = 1
              addCourseForm.related_courses = []
              addCourseForm.months = []
              form.value.reset()
              Vue.swal({
                title: 'Course Content Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })

              addCourseForm.category_id = ''
              addCourseForm.course_id = ''
              addCourseForm.description = ''
              addCourseForm.objectives = ''
              addCourseForm.attenders = ''
              dayContentForm.value = ''
              dayContent.value = []
            })
            .catch((error) => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }

    const linkHandler = (editorRef) => {
      const range = proxy.$refs[editorRef].quill.getSelection()
      if (range) {
        const url = prompt('Enter the URL')
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self'
          const linkValue = `<a href="${url}" target="${target}">${proxy.$refs[editorRef].quill.getText(range)}</a>`
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
        }
      }
    }

    const getEditorOptions = (editorRef) => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
        
            ['bold', 'italic', 'underline', 'strike'],
            ['clean'],
            ['undo', 'redo'],
            ['paragraph'],

          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef),
          },
        },
      },
    })

    const getMonths = (item) => {
      addCourseForm.months = item.months
      addCourseForm.description = item.description
      addCourseForm.objectives = item.objectives
      addCourseForm.attenders = item.attenders
      addCourseForm.related_courses = JSON.parse(item.related_courses)
      addCourseForm.og_image = item.og_image
      addCourseForm.alt_content_image = item.alt_content_image
    }

    return {
      isLoading,
      getMonths,
      newDay,
      removeMonth,
      newMonth,
      newWeek,
      removeDay,
      removeWeek,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      addCourseFormvalidate,
      daycontentArray,
      coursesList,
      categoriesList,
      durationList,
      simple,
      getCoursesByCategory,
      getCourse,
      RelatedCourse,
      save,
      required,
      email,
      dayContent,
      dayContentForm,
      form,
      getEditorOptions,
       imageHandler,
      insertImage,
      imageProperties,
      linkHandler,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style lang="scss">
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}

/* Custom styles to fix the overlapping issue */
.blog-edit-wrapper {
  .quill-editor {
    max-height: 400px; /* Limit height to prevent overflow */
    overflow-y: auto; /* Add scrollbar if content overflows */
  }

  .ql-toolbar.ql-snow {
    z-index: 10; /* Ensure toolbar is above other elements */
  }

  .ql-container.ql-snow {
    z-index: 9; /* Ensure container is below the toolbar */
  }
}
</style>
